import React from 'react';
import { css } from '@emotion/react'
import { useStaticQuery, graphql } from "gatsby"
import { Box } from '@material-ui/core';
import AppStoreSvg from '../../assets/svg/appstore.svg'
import { withThemeProps } from '@findep/microfronts-core';
import PropTypes from 'prop-types'

const FelicidadesApp = (props) => {
  const {TitleAndroid, TitleApple}= props
    const data = useStaticQuery(graphql`
    query {
        allComponentsJson(filter: {component: {eq: "felicidades-app"}}) {
          edges {
            node {
              id
              url {
                url
                company
                so
              }
            }
          }
        }
      }
  `)
  const url = data.allComponentsJson.edges[0].node.url.filter(item => item.company === props.company)

  const Link = ({url: customUrl, imgSrc, imgAlt, customCss,textAlt}) => {
    return (
      <a 
        css={customCss}
        href={customUrl}
        target="_blank" 
        rel="noopener noreferrer"        
      >
        <img 
          alt={imgAlt}
          src={imgSrc}
          width="100%"
        />
      </a>
    )
  }

  const IosLink = ({url: customUrl}) => (
    <Link 
      url={customUrl}
      imgAlt={TitleApple}
      imgSrc={AppStoreSvg}
      customCss={css`width: 53%;`}
    />
  )

  const AndroidLink = ({url: customUrl}) => (
    <Link 
      url={customUrl}
      imgAlt={TitleAndroid}
      imgSrc="https://play.google.com/intl/en_us/badges/static/images/badges/es_badge_web_generic.png"
      customCss={css`width: 60%;`}
    />
  )

    return (
        <Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">

            {
              url.map(node => (
                node.so === 'ANDROID' ? <AndroidLink url={node.url} /> : <IosLink url={node.url} />
              ))
            }
            </Box>
    );
}

export default withThemeProps(FelicidadesApp, 'VDPNFelicidadesApp')

FelicidadesApp.propTypes = {
    TitleAndroid: PropTypes.string,
    TitleApple: PropTypes.string,
    // TitleButton: PropTypes.string,
}

FelicidadesApp.defaultProps = {
TitleAndroid: "Disponible en Google Play",
TitleApple: "Descárgalo en el App Store"
}

