import React from "react"
import SEO from "../components/seo"
// import Layout from '../layouts/findep'
import LayoutsComponent from '../layouts/LayoutsComponent';
import FelicidadesOld from '../components/pages/FelicidadesOld'


const FelicidadesPage = ({ pageContext, location }) => (
  <LayoutsComponent
    broker={pageContext.broker}
    layout={pageContext.layout}
    pageContext={pageContext}
    location={location}
    companyName={pageContext.company}
    render={() => (
      <>
        <SEO title={pageContext.title} />
        <FelicidadesOld companyName={pageContext.company} pageContext={pageContext} />
      </>
    )}
  />
)

export default FelicidadesPage